import React from "react"

import calcStyles from './display_styles.module.scss'

const DisplayCompP = ({numerator, denominator, label, perc, usePct, calc}) => {

    // func for imperceisely handling float-point rounding hell //
    function truncate (num, places) {
        return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
    }

    // translate inputs to ints //
    const nI = parseInt(numerator)
    const dI = parseInt(denominator)

    // handle init blanks //
    if (numerator === '' || denominator === '') {
        calc = false
    }

    // handle value display //
    var displayVal = '--'

    if (calc) {
        if (usePct) {
            displayVal = `${truncate(nI / dI * 100, perc)}%`
        } else {
            displayVal = `${truncate(nI / dI, perc)}`
        }
    }

    return (

        <div className={calcStyles.detail_container_sub}>
            <p className={calcStyles.detail_container_sub_title}>
                {label}
            </p>
            <p className={calcStyles.detail_container_sub_value}>
                {displayVal}
            </p>
        </div>
    )

}

export default DisplayCompP
