import React from "react"

import Layout from '../../components/layout'
import Head from '../../components/head'
import calcStyles from './calculators.module.scss'

import PasserRatingCalc from "../../components/Calculators/passer_rating"

const PasserCalculator = () => {

    return (
        <Layout>
            <Head
                title='Passer Rating Calculator'
                pageDescription="A passer rating calculator for NFL Quarterbacks"
            />
            <PasserRatingCalc/>
            <div className={calcStyles.content_container}>
                <h3 className={calcStyles.content_h3}>
                    Passer Rating
                </h3>
                <p className={calcStyles.content_p}>
                    Passer rating (also known as QB rating or Quarterback Rating) is a metric that's used to measure the efficiency of quarterbacks in the NFL. Though originally developed in 1973, passer rating is still one of the most popular statistics available for quantifying the play of NFL quarterbacks.<br className={calcStyles.content_break}/>
                    Passer rating rewards quarterbacks for touchdown passes, yards gained, and completions, while penalizing quarterbacks for interceptions. It does not consider rushing yards, sacks, or fumbles. Each individual stat is measured on a per attempt basis, which makes passer rating a measure of efficiency, not total performance. <br className={calcStyles.content_break}/>
                    In the NFL, passer rating constrains the score a quarterback gets for any individual metric to between 0 and 2.375 before dividing the individual factors by 6 and multiplying the entire equation by 100. As a result, NFL passer ratings can range between a minimum 0 and a maximum of 158.3. A rating of 158.3 is considered a "perfect" passer rating since it's the highest rating a quarterback can receive. In general, a rating above 100 is considered quite good.<br className={calcStyles.content_break}/>
                    Passer rating does have its limitations. In today's NFL, quarterbacks are throwing for more yards, more touchdown passes, and fewer interceptions. As a result, today's ratings are meaningfully higher than those of the past. Additionally, since passer rating does not include any scoring for yards lost to sacks or yards gained rushing or rushing touchdowns, it doesn't paint a full picture of quarterback's performance. That said, the formula is still quite popular and this calculator can help build a better understanding of how it works.
                </p>
                <h3 className={calcStyles.content_h3}>
                    Passer rating calculator
                </h3>
                <p className={calcStyles.content_p}>
                    Entering completions, attempts, yards, passing touchdowns, and interceptions into the fields above, will instantly calculate a passer rating. Since the passer rating calculator updates in real time, you can easily see how changes to individual statistics change the overall rating.<br className={calcStyles.content_break}/>
                    The passer rating calculator also includes instant validation and will tell you if you've entered a stat line that can't exist. For instance, if you enter more touchdown passes than total completions (which can't occur in a real game), you'll see an error and the passer rating calculator won't update.<br className={calcStyles.content_break}/>
                    In addition to calculating a passer rating, the calculator will also display a few other useful rate statistics -- completion percentage, yards per attempt, touchdowns per attempt, and interceptions per attempt.<br className={calcStyles.content_break}/>
                </p>
            </div>
        </Layout>
    )

}

export default PasserCalculator