import React, { useState } from "react"
import DisplayCompP from "./helpers/Passing Stats/display_comp_pct";
import DisplayPr from "./helpers/pr_calc"

import calcStyles from './passer_rating.module.scss'


const PasserRatingCalc = () => {

    // helpers //
    function isPosInt(value) {
        var x = parseFloat(value);
        return !isNaN(value) && (x | 0) === x && x >= 0;
    }

    function isInt(value) {
        var x = parseFloat(value);
        return !isNaN(value) && (x | 0) === x;
    }

    // set initial states //
    const [cmp, setCmp] = useState('')
    const [att, setAtt] = useState('')
    const [yds, setYds] = useState('')
    const [tds, setTds] = useState('')
    const [int, setInt] = useState('')
    // error states //
    const [cmpE, setCmpE] = useState(false)
    const [attE, setAttE] = useState(false)
    const [ydsE, setYdsE] = useState(false)
    const [tdsE, setTdsE] = useState(false)
    const [intE, setIntE] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [errorMsgVisible, setErrorMsgVisible] = useState(false)

    // update entries when value is wrong //
    const updateWithError = (activeEntry, rawEntry) => {
        
        if (activeEntry === 'cmp') {
            setErrorMsgVisible(true)
            setCmpE(true)
            setCmp(rawEntry)
        }
        if (activeEntry === 'att') {
            setErrorMsgVisible(true)
            setAttE(true)
            setAtt(rawEntry)
        }
        if (activeEntry === 'yds') {
            setErrorMsgVisible(true)
            setYdsE(true)
            setYds(rawEntry)
        }
        if (activeEntry === 'tds') {
            setErrorMsgVisible(true)
            setTdsE(true)
            setTds(rawEntry)
        }
        if (activeEntry === 'int') {
            setErrorMsgVisible(true)
            setIntE(true)
            setInt(rawEntry)
        }

    }

    // update entries when value is wrong //
    const updateWithValue = (activeEntry, rawEntry) => {
        
        if (activeEntry === 'cmp') {
            setErrorMsgVisible(false)
            setErrorMsg('')
            setCmpE(false)
            setCmp(rawEntry)
            // clear other errors that might be fixed //
            if (isPosInt(att)) {
                if (parseInt(rawEntry) <= parseInt(att)) {
                    if (isPosInt(int)) {
                        if (parseInt(rawEntry) <= parseInt(att) - parseInt(int)) {
                            setAttE(false)
                            setIntE(false)
                        }
                    } else {
                        setAttE(false)
                    }
                }
            }
            if (parseInt(rawEntry) >= parseInt(tds)) {
                setTdsE(false)
            }
            // final strict check to clear all straggling validation errors //
            if (
                (parseInt(rawEntry) + parseInt(int) <= parseInt(att)) &&
                (parseInt(rawEntry) >= parseInt(tds))
            ) {
                setAttE(false)
                setCmpE(false)
                setIntE(false)
                setTdsE(false)
            }
        }
        if (activeEntry === 'att') {
            setErrorMsgVisible(false)
            setErrorMsg('')
            setAttE(false)
            setAtt(rawEntry)
            // clear other errors that might be fixed //
            if (isPosInt(cmp)) {
                if (parseInt(cmp) <= parseInt(rawEntry)) {
                    if (isPosInt(int)) {
                        if (parseInt(cmp) <= parseInt(rawEntry) - parseInt(int)) {
                            setCmpE(false)
                            setIntE(false)
                        }
                    } else {
                        setCmpE(false)
                    }
                }
            }
            // final strict check to clear all straggling validation errors //
            if (
                (parseInt(cmp) + parseInt(int) <= parseInt(rawEntry)) &&
                (parseInt(cmp) >= parseInt(tds))
            ) {
                setAttE(false)
                setCmpE(false)
                setIntE(false)
                setTdsE(false)
            }
        }
        if (activeEntry === 'yds') {
            setErrorMsgVisible(false)
            setErrorMsg('')
            setYdsE(false)
            setYds(rawEntry)
        }
        if (activeEntry === 'tds') {
            setErrorMsgVisible(false)
            setErrorMsg('')
            setTdsE(false)
            setTds(rawEntry)
            // clear other errors that might be fixed //
            if (parseInt(rawEntry) <= parseInt(cmp)) {
                setCmpE(false)
            }
            // final strict check to clear all straggling validation errors //
            if (
                (parseInt(cmp) + parseInt(int) <= parseInt(att)) &&
                (parseInt(cmp) >= parseInt(rawEntry))
            ) {
                setAttE(false)
                setCmpE(false)
                setIntE(false)
                setTdsE(false)
            }
        }
        if (activeEntry === 'int') {
            setErrorMsgVisible(false)
            setErrorMsg('')
            setIntE(false)
            setInt(rawEntry)
            // clear other errors that might be fixed //
            if (parseInt(cmp) <= parseInt(att) - parseInt(rawEntry)) {
                setCmpE(false)
                setAttE(false)
            }
            // final strict check to clear all straggling validation errors //
            if (
                (parseInt(cmp) + parseInt(rawEntry) <= parseInt(att)) &&
                (parseInt(cmp) >= parseInt(tds))
            ) {
                setAttE(false)
                setCmpE(false)
                setIntE(false)
                setTdsE(false)
            }
        }



    }


    // handle the actual field update //
    const updateCmp = event => {

        const cmpV = event.target.value
        
        if (isPosInt(cmpV)) {
            if (parseInt(cmpV) < parseInt(tds)) {
                // if both are ints, but cmp is > att, then error
                setErrorMsg('Completions must be greater than or equal to Touchdowns')
                updateWithError('cmp', cmpV)
            } else if (isPosInt(int)) {
                if (parseInt(cmpV) + parseInt(int) > parseInt(att)) {
                    setErrorMsg('Completions plus interceptions must be less than or equal to attempts')
                    updateWithError('cmp', cmpV)
                } else {
                    updateWithValue('cmp', cmpV)
                }
            } else if (parseInt(cmpV) > parseInt(att)) {
                setErrorMsg('Completions must be less than or equal to attempts')
                updateWithError('cmp', cmpV)
            } else {
                updateWithValue('cmp', cmpV)
            }
        } else {
            setErrorMsg('Completions must a positive whole number')
            updateWithError('cmp', cmpV)
        }

    }

    const updateAtt = event => {

        const attV = event.target.value
        
        if (isPosInt(attV)) {
            if (parseInt(attV) < parseInt(int)) {
                setErrorMsg('Attemps must be great than or equal to Interceptions')
                updateWithError('att', attV)
            } else if (isPosInt(int)) {
                if (parseInt(attV) < parseInt(cmp) + parseInt(int)) {
                    setErrorMsg('Attemps must be greater than or equal to completions and interceptions')
                    updateWithError('att', attV)
                } else {
                    updateWithValue('att', attV)
                }
            } else if (parseInt(attV) < parseInt(cmp)) {
                // if both are ints, but cmp is > att, then error
                setErrorMsg('Attemps must be greater than or equal to completions')
                updateWithError('att', attV)
            } else {
                // otherwise just upate 
                updateWithValue('att', attV)
            }
        } else {
            setErrorMsg('Attemps must a positive whole number')
            updateWithError('att', attV)
        }

    }

    const updateYds = event => {

        const ydsV = event.target.value
        
        if (isInt(ydsV)) {
            // if att is good, but cmp is not, still update att
            updateWithValue('yds', ydsV)
        } else {
            setErrorMsg('Yards must be a whole number')
            updateWithError('yds', ydsV)
        }
    
    }

    const updateTds = event => {
        // need to make sure tds are greater than completions
        const tdsV = event.target.value
        
        if (isPosInt(tdsV)) {
            if (parseInt(tdsV) > parseInt(cmp)) {
                setErrorMsg('Touchdowns must be less than or equal to completions')
                updateWithError('tds', tdsV)
            } else if (parseInt(tdsV) > parseInt(att)) {
                setErrorMsg('Touchdowns must be less than or equal to attempts')
                updateWithError('tds', tdsV) 
            } else {
                // if att is good, but cmp is not, still update att
                updateWithValue('tds', tdsV)
            }
        } else {
            setErrorMsg('Touchdowns must be a postive whole number')
            updateWithError('tds', tdsV)
        }
    
    }

    const updateInts = event => {

        const intV = event.target.value
        
        if (isPosInt(intV)) {
            if (isPosInt(cmp)) {
                if (parseInt(intV) + parseInt(cmp) > parseInt(att)) {
                    setErrorMsg('Interecptions plus completions must be less than or equal to attempts')
                    updateWithError('int', intV)
                } else {
                    updateWithValue('int', intV)
                }
            } else if (parseInt(intV) > parseInt(att)) {
                setErrorMsg('Interecptions must be less than or equal to attempts')
                updateWithError('int', intV)
            } else {
                updateWithValue('int', intV)
            }
        } else {
            setErrorMsg('Interecptions must be a postive whole number')
            updateWithError('int', intV)
        }
    
    }

    // var to flag if calc can be made //
    var perfCalc = false

    if (attE || cmpE || ydsE || tdsE || intE) {
        perfCalc = false
    } else {
        perfCalc = true
    }


    return (

        <div className={calcStyles.calc_wrapper}>
            <div className={calcStyles.parlay_container}>
                <div className={calcStyles.parlay_panel} data-alignment='left'>
                    <div className={calcStyles.param_container}>
                        <h2 className={calcStyles.title}>
                            Quarterback Stats
                        </h2>
                        <div className={calcStyles.param_box}>
                            <h3 className={calcStyles.param_title}>
                                Completions
                            </h3>
                            <div className={calcStyles.check_input_container}>
                                <div className={calcStyles.check_div} data-active={cmpE}>
                                    {(cmpE===false) ? '\u2713' : '\u2297' }
                                </div>
                                <input className={calcStyles.input_value}
                                    id={'cmp'}
                                    name={'cmp'}
                                    type="text"
                                    value={cmp}
                                    onChange={updateCmp}
                                />
                            </div>
                        </div>
                        <div className={calcStyles.param_box}>
                            <h3 className={calcStyles.param_title}>
                                Attemps
                            </h3>
                            <div className={calcStyles.check_input_container}>
                                <div className={calcStyles.check_div} data-active={attE}>
                                    {(attE===false) ? '\u2713' : '\u2297' }
                                </div>
                                <input className={calcStyles.input_value}
                                    id={'att'}
                                    name={'att'}
                                    type="text"
                                    value={att}
                                    onChange={updateAtt}
                                />
                            </div>
                        </div>
                        <div className={calcStyles.param_box}>
                            <h3 className={calcStyles.param_title}>
                                Yards
                            </h3>
                            <div className={calcStyles.check_input_container}>
                                <div className={calcStyles.check_div} data-active={ydsE}>
                                    {(ydsE===false) ? '\u2713' : '\u2297' }
                                </div>
                                <input className={calcStyles.input_value}
                                    id={'yds'}
                                    name={'yds'}
                                    type="text"
                                    value={yds}
                                    onChange={updateYds}
                                />
                            </div>
                        </div>
                        <div className={calcStyles.param_box}>
                            <h3 className={calcStyles.param_title}>
                                Touchdowns
                            </h3>
                            <div className={calcStyles.check_input_container}>
                                <div className={calcStyles.check_div} data-active={tdsE}>
                                    {(tdsE===false) ? '\u2713' : '\u2297' }
                                </div>
                                <input className={calcStyles.input_value}
                                    id={'tds'}
                                    name={'tds'}
                                    type="text"
                                    value={tds}
                                    onChange={updateTds}
                                />
                            </div>
                        </div>
                        <div className={calcStyles.param_box}>
                            <h3 className={calcStyles.param_title}>
                                Interceptions
                            </h3>
                            <div className={calcStyles.check_input_container}>
                                <div className={calcStyles.check_div} data-active={intE}>
                                    {(intE===false) ? '\u2713' : '\u2297' }
                                </div>
                                <input className={calcStyles.input_value}
                                    id={'int'}
                                    name={'int'}
                                    type="text"
                                    value={int}
                                    onChange={updateInts}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={calcStyles.parlay_panel} data-alignment='right'>
                    <div className={calcStyles.param_container}>
                        <DisplayPr
                            cmp={cmp}
                            att={att}
                            yds={yds}
                            tds={tds}
                            int={int}
                            calc={perfCalc}
                        />
                    </div>
                    <div className={calcStyles.param_container}>
                        <DisplayCompP
                            numerator={cmp}
                            denominator={att}
                            usePct={true}
                            perc={1}
                            label={'Completion %'}
                            calc={(cmpE===false && attE===false) ? true : false}
                        />
                        <DisplayCompP
                            numerator={yds}
                            denominator={att}
                            usePct={false}
                            perc={1}
                            label={'Yards / Att'}
                            calc={(ydsE===false && attE===false) ? true : false}
                        />
                        <DisplayCompP
                            numerator={tds}
                            denominator={att}
                            usePct={false}
                            perc={2}
                            label={'TDs / Att'}
                            calc={(tdsE===false && attE===false) ? true : false}
                        />
                        <DisplayCompP
                            numerator={int}
                            denominator={att}
                            usePct={false}
                            perc={2}
                            label={'INTs / Att'}
                            calc={(intE===false && attE===false) ? true : false}
                        />
                    </div>
                </div>
                <div className={calcStyles.alert_container} data-active={errorMsgVisible}>
                    <p className={calcStyles.alert} data-active={errorMsgVisible}>
                        {errorMsg}
                    </p>
                </div>
            </div>
        </div>

    )

}

export default PasserRatingCalc
    