import React from "react"

import calcStyles from '../passer_rating.module.scss'

const DisplayPr = ({cmp, att, yds, tds, int, calc }) => {

    // colors
    const worst_color = '#ffd9e7'
    const typical_color = '#fffdd6'
    const best_color = '#d4fff8'
    const blank_color = '#dedede'

    // translate inputs to ints //
    const cmpI = parseInt(cmp)
    const attI = parseInt(att)
    const ydsI = parseInt(yds)
    const tdsI = parseInt(tds)
    const intI = parseInt(int)

    // func for imperceisely handling float-point rounding hell //
    function truncate (num, places) {
        return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
    }

    // display vars //
    var prDisplay = ''
    var prColor = ''

    // if no errors, calc //
    if (calc) {

        if (isNaN(cmpI) || isNaN(attI) || isNaN(ydsI) || isNaN(tdsI) || isNaN(intI)) {
            prDisplay = '--'
            prColor = blank_color 
        } else {

            const a = Math.max(0, Math.min(2.375, (cmpI / attI - .3) * 5))
            const b = Math.max(0, Math.min(2.375, (ydsI / attI -3) * 0.25))
            const c = Math.max(0, Math.min(2.375, (tdsI / attI) * 20))
            const d = Math.max(0, Math.min(2.375, 2.375 - (intI / attI * 25)))

            const pr = ((a + b + c + d) / 6) * 100

            prDisplay = truncate(pr, 1)

            if (pr < 80) {
                prColor = worst_color
            } else if (pr < 95) {
                prColor = typical_color
            } else {
                prColor = best_color
            }
        
        }

    } else {
        prDisplay = '--'
        prColor = blank_color
    }

    return (

        <div className={calcStyles.pr_container}>
            <h3 className={calcStyles.title}>
                Passer Rating
            </h3>
            <div className={calcStyles.pr_box} style={{'backgroundColor' : prColor}}>
                <p className={calcStyles.pr_value}>
                    {prDisplay}
                </p>
            </div>
        </div>

    )

}

export default DisplayPr

